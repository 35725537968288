import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import { graphql } from "gatsby";
import * as React from "react";
import { ArticleCard, Layout, Seo } from "../components";
import "../styles/screen.scss";

const seoBundle = {
  title: "JMF Steel Construction Projects",
  subtitle:
    "Different steel construction projects we've completed in Boise, Idaho",
  description:
    "Having served Boise and the surrounding Treasure Valley for many years, here are some projects we've undertaken",
  slug: "/projects-landing",
};

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      gatsbyImageData(
        height: 400
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
`;

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "01.png" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "02.png" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "03.png" }) {
      ...fluidImage
    }
    imageFour: file(relativePath: { eq: "04.png" }) {
      ...fluidImage
    }
    imageFive: file(relativePath: { eq: "05.png" }) {
      ...fluidImage
    }
    imageSix: file(relativePath: { eq: "06.png" }) {
      ...fluidImage
    }
    imageSeven: file(relativePath: { eq: "07.png" }) {
      ...fluidImage
    }
    imageEight: file(relativePath: { eq: "08.png" }) {
      ...fluidImage
    }
    imageNine: file(relativePath: { eq: "09.png" }) {
      ...fluidImage
    }
  }
`;

const Projects = ({ location, data }) => {
  const articles = [
    {
      title: "Cascade Airport",
      description: `The Cascade Airport needed more storage for snow removal equipment. We
          erected them an extremely tall 45' X 45' steel storage facility.`,
      image: data.imageTwo,
      alt: "Cascade, Idaho Airport",
      href: "/projects/cascade-airport",
    },
    {
      title: "Farm Equipment USA",
      description: `When Ben Nelson, owner of Farm Equipment USA, first came to us about a
          steel building, he was concerned that we couldn't build him a large
          enough dealership. The truth is that steel makes it easy to build as
          big or as small as desired.`,
      href: "/projects/farm-equipment",
      alt: "new roof on house in Kuna, Idaho",
      image: data.imageOne,
    },
    {
      title: "Steel Business Park",
      description: `This large project is in a commercial setting. Both aspects make steel a perfect choice.`,
      href: "/projects/csdi",
      alt: "CSDI Business Park",
      image: data.imageEight,
    },
    {
      title: "Rural Barn",
      description: `One of the amazing things about steel is how versatile it can be.`,
      href: "/projects/barn",
      alt: "Custom Steel Barn",
      image: data.imageSix,
    },
    {
      title: "Ski Resort Storage Facility",
      description: `Rotarun needed extra storage for all of their snow removal equipment.
          What was unique about this project is that the building needed to be
          zoned for an area that gets more snow loads and much colder weather.`,
      href: "/projects/rotarun",
      alt: "Ski Resort Storage Facility",
      image: data.imageFour,
    },
    {
      title: "Indoor Basketball Court",
      description: `These kinds of projects are always fun. The fact that steel
          construction can be used for an indoor basketball court shows that
          there's many uses for steel buildings.`,
      href: "/projects/basketball-court",
      alt: "Indoor Basketball Court",
      image: data.imageNine,
    },
    {
      title: "Detached Garage",
      description: `A common use for steel construction we see all of the time is for a
          detached garage.`,
      href: "/projects/garage",
      alt: "Steel Detached Garage",
      image: data.imageThree,
    },
    {
      title: "Steel Shop",
      description: `LMAC Ranch needed a facilty to be both a shop and equipment storage.
          Steel construction really suits these
          kinds of projects.`,
      href: "/projects/lmac",
      alt: "Steel Shop",
      image: data.imageSeven,
    },
    {
      title: "Steel Worshop",
      description: `Bill Boosembark has been restoring antique cars for many years. It was
          our pleasure to be a part of providing him with a great shop to
          facilitate the restoration.`,
      href: "/projects/workshop",
      alt: "Steel Construction Workshop",
      image: data.imageFive,
    },
  ];

  return (
    <Layout title={"Home"} location={location}>
      <Seo seoBundle={seoBundle} />
      <Flex
        maxW='1450px'
        h='100%'
        // header takes up 40/50 pixels
        pt={["150px", null, "150px"]}
        px={["20px", null, "100px"]}
        pb={["60px", null, "100px"]}
        align='center'
        justify='center'
        flexDirection='column'
        mx='auto'
      >
        <Heading
          as='h1'
          color='dkGrey'
          mb={["20px", null, null, "10px"]}
          mt='0px'
          fontWeight={800}
          fontSize={["3em", "4em"]}
          fontFamily='heading'
          lineHeight={["100%", null, null, "120%"]}
          textAlign='center'
        >
          Recent Steel Construction Projects from Boise Experts
        </Heading>
        <Heading
          as='h2'
          color='dkGrey'
          mb={["20px", null, null, "10px"]}
          mt='0px'
          fontWeight={800}
          fontSize={["1.5em", "2em"]}
          fontFamily='body'
          lineHeight={["120%"]}
          textAlign='center'
        >
          JMF Steel Construction
        </Heading>
        <Text
          mt='20px'
          mb='7'
          textAlign='center'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
        >
          Having served Boise and the surrounding Treasure Valley for decades,
          we've been able to build a long lasting relationship with our
          customers -- and we never get over the satisfaction of delivering
          excellent service on every project.
        </Text>
        <Box mt='40px'>
          {articles.map((article) => {
            return <ArticleCard article={article} />;
          })}
        </Box>
        <Divider maxW='600px' my='40px' orientation='horizontal' />
        <Heading as='h3' textAlign='center'>
          JMF Construction
        </Heading>
        <Heading as='h4' fontFamily='body' fontSize='16px'>
          A Boise, Idaho Company
        </Heading>
        <Text
          mt='20px'
          mb='7'
          textAlign='center'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
        >
          We’ve done projects all over the Treasure Valley and beyond for
          businesses such as Simplot Western Stockmen’s, CSDI, Champion Produce
          in Parma, and Farm Equipment USA in Fruitland and Jerome. Have a small
          project? You’ve come to the right place. We build smaller projects
          regularly, not just the large scale ones.
        </Text>
        <Text
          mt='20px'
          mb='7'
          textAlign='center'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
        >
          Here at JMF, we make it our business to get to know you and your
          unique situation. Call us today!
        </Text>
        <Text
          mb='40px'
          mt='20px'
          fontSize={24}
          textAlign='center'
          maxW='800px'
          lineHeight='120%'
          fontWeight='bold'
          color='dkGrey'
        >
          Call us at <a href='tel:2088801062'>(208) 880-1062</a>
        </Text>
      </Flex>
    </Layout>
  );
};

export default Projects;
